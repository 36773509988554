
export const GET_PRODUCTS_LIST_REQUEST = 'get::productList::request'
export const GET_PRODUCTS_LIST_SUCCESS = 'get::productList::success'
export const GET_PRODUCTS_LIST_FAIL = 'get::productList::fail'

export const GET_PRODUCTS_REQUEST = 'get::productArticle::request'
export const GET_PRODUCTS_SUCCESS = 'get::productArticle::success'
export const GET_PRODUCTS_FAIL = 'get::productArticle::fail'

export const GET_PRODUCTS_RESET = 'get::productArticle::reset'
export const GET_PRODUCTS_LIST_RESET = 'get::productList::reset'
