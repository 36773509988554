import * as actionTypes from './action-types'
import axios from 'axios'
import HTMLReactParser from 'html-react-parser'
import { TIME_LIMIT_FOR_FETCH } from '../../utils/constants'
import *  as APP_CONFIG from '../../config'

export const getProductList = props => {
  return (dispatch, getState) =>

  {
    const timeSinceLastFetch = getState().productsReducer.productList.lastFetch
    let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
    if(isDataStale) {
      const maxItemLength = 100
      const pageNo = 1
      const category = 36


      let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${category}&page=${pageNo}&_embed&per_page=${maxItemLength}`
      if (window.DEBUG) console.time(url)
      dispatch({ type: actionTypes.GET_PRODUCTS_LIST_REQUEST })
      if (window.DEBUG) console.time(url)
      return axios
        .get(url)
        .then(response => {
          if (window.DEBUG) console.timeEnd(url)

          const responseData = response && response.data ? response.data : []

          let menuSections =[
            {name:'Breakfast', tag:54, items:[], menuItem:true},
            {name:'Salads', tag:55, items:[], menuItem:true},
            {name:'Cold Mezza', tag:62, items:[], menuItem:true},
            {name:'Hot Mezza', tag:65, items:[], menuItem:true},
            {name:'Mains', tag:60, items:[], menuItem:true},
            {name:'Seafood', tag:63, items:[], menuItem:true},
            {name:'Banquets', tag:66, items:[], menuItem:true},
            {name:'Kids Menu', tag:67, items:[], menuItem:true},
            {name:'Wraps', tag:68, items:[], menuItem:true},
            {name:'Vegetarian', tag:57, items:[], menuItem:true},
          ]

          if(responseData.length > 0){
            for(const item of responseData){
              if(item.tags && item.tags.length > 0){
                for(const tag of item.tags){
                  for(let menuSection of menuSections){
                    if(menuSection.tag === tag){
                      menuSection.items.push(item)
                    }
                  }
                }
              }
            }
          }


          dispatch({
            type: actionTypes.GET_PRODUCTS_LIST_SUCCESS,
            payload: menuSections
          })
        })
        .catch(error => {
          if (window.DEBUG) console.timeEnd(url)
          console.log(error)
          dispatch({
            type: actionTypes.GET_PRODUCTS_LIST_FAIL,
            error
          })
        })
    }

  }
}

export const getProductArticle = article => {

  return (dispatch, getState) => {

    const url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&slug=${article}&_embed`
    dispatch({ type: actionTypes.GET_PRODUCTS_REQUEST })
    if (window.DEBUG) console.time(url)
    return axios
      .get(url)
      .then(response => {
        if (window.DEBUG) console.timeEnd(url)

        const excerpt = HTMLReactParser(response.data[0].excerpt.rendered)[0].props.children
        const priceValues = Array.isArray(excerpt) ? `${excerpt[0]} ${excerpt[2]}`: excerpt;

        dispatch({
          type: actionTypes.GET_PRODUCTS_SUCCESS,
          payload: {
            title:HTMLReactParser(response.data[0].title.rendered),
            price:priceValues,
            content:HTMLReactParser(response.data[0].content.rendered),
            featureImg: (response.data[0] &&
              response.data[0]['_embedded'] &&
              response.data[0]['_embedded']['wp:featuredmedia'] &&
              response.data[0]['_embedded']['wp:featuredmedia'][0] &&
              response.data[0]['_embedded']['wp:featuredmedia'][0]['source_url']) ?
              response.data[0]['_embedded']['wp:featuredmedia'][0]['source_url'] :
              ''
          }
        })
      })
      .catch(error => {
        if (window.DEBUG) console.timeEnd(url)
        console.log(error)
        dispatch({
          type: actionTypes.GET_PRODUCTS_FAIL,
          error
        })
      })
  }
}
export const resetProductArticle = props => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_PRODUCTS_RESET,
    })
  }
}
export const resetBlogList = props => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.GET_PRODUCTS_LIST_RESET,
    })
  }
}
