import styled, { css } from 'styled-components'

//todo move these out into global
const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '710px',
  laptop: '1070px',
  laptopL: '1440px',
  desktop: '2560px'
}

const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};

export const RoutesStyledDiv = styled.div`
  background-image: ${props => props.backgroundImage ? `url(../../../assets/img/backgrounds/${props.backgroundImage})` :'initial'};
  background-attachment: fixed;
`

