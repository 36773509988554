import React, { Fragment,useState,useRef,useEffect } from 'react'
import ReactModal from 'react-modal'
import MenuContent from './menu-content'
import { connect } from 'react-redux'
import hamburgerReducer from '../redux/reducers'
import {toggleHamburger} from '../redux/action'
import StoreManager from '../../../../redux/store-manager'
import Animate from 'animate.css-react'
import { gsap } from "gsap"
StoreManager.registerReducers({minimenu:hamburgerReducer})

require ('./HeaderHamburgerMenu.scss')

const HeaderHamburgerMenu = props =>{
  //props get passed from the main header component
  const {title,links,image,toggleHamburger} = props
  const hamburgerIcon = useRef()
  const [hamburgerStatus, setHamburgerStatus] = useState(false)

  useEffect(() => {
    // console.log(hamburgerStatus,props.hamburgerStatus)
    hamburgerIcon.current.className = hamburgerStatus ? 'active' : ''
    if(hamburgerStatus === props.hamburgerStatus)return
    // console.log(hamburgerStatus)
    toggleHamburger(hamburgerStatus)
    //set it the oppisite to props so we dont have an inifite loop
    setHamburgerStatus(!props.hamburgerStatus)
    gsap.fromTo(".modalContent", {x:-20}, { x:0,  duration: 1}).play()
    hamburgerStatus ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"
  }, [hamburgerStatus])

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  // const toggleBurger = () => {
  //     console.log(hamburgerStatus)
  //     toggleHamburger(!hamburgerStatus)
  // }

  return (
    <Fragment>
      <div id="menuButton" onClick={() =>setHamburgerStatus(!hamburgerStatus)} >
        <a id="hamburger-icon" href="#" onClick={() =>setHamburgerStatus(!hamburgerStatus)} title={title} ref={hamburgerIcon}>
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </a>
      </div>
      <ReactModal
        isOpen={hamburgerStatus}
        // isOpen={true}
        // overlayClassName='modalOverlay'
        // className='modalContent'
        overlayClassName='ReactModal__Overlay'
        className='modalContent2'
        onRequestClose={()=> {
          //console.log('on request close')
          setHamburgerStatus(false)
          }
        }
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        handleContentOnClick={()=> console.log('content clicked')}
        handleOverlayOnClick={()=> console.log('overlay clicked')}
      >
        <MenuContent
          links={links}
          onClickFunction={() => setHamburgerStatus(false)}

        />
      </ReactModal>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state.minimenu)
  // const {hamburgerStatus} = state.minimenu
  return {hamburgerStatus: state.minimenu.hamburgerStatus}
}

const mapDispatchToProps = {
    toggleHamburger
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderHamburgerMenu)
