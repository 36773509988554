import React, { Fragment } from 'react'
import './App.css'
import "animate.css/animate.min.css"
import Routes from './routes'
import Footer from './components/common/footer'
import Header from './components/common/header'
import {CircleArrow as ScrollUpButton} from './components/common/back-to-top/backToTop'

function App() {
  return (
    <Fragment>
      <div className="content">
        <Header />
        <Routes />
      </div>
      <div className="footer">
        <Footer />
      </div>
      <ScrollUpButton />
    </Fragment>
  );
}

export default App
