import { useEffect, useState } from 'react'
import forOwn from 'lodash/forOwn'
export const TIME_LIMIT_FOR_FETCH = 3000000


const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 710,
  laptop: 1070,
  laptopL: 1440,
  desktop: 2560
}

export const deviceViewports = {
  mobileS: `(max-width: ${size.mobileS}px)`,
  mobileM: `(max-width: ${size.mobileM}px)`,
  mobileL: `(max-width: ${size.mobileL}px)`,
  tablet: `(max-width: ${size.tablet}px)`,
  laptop: `(max-width: ${size.laptop}px)`,
  laptopL: `(max-width: ${size.laptopL}px)`,
  desktop: `(max-width: ${size.desktop}px)`,
  desktopL: `(max-width: ${size.desktop}px)`
}

export const responsiveFlags = () => {

  let finalObj = {}

  forOwn(size, (value, key) => {

    finalObj[key] = value >= useCurrentWidth()
  } )
  // console.log(finalObj)
  return finalObj
}

export const  useCurrentWidth = () => {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

// in this case useEffect will execute only once because
// it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}

const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

export const  useCurrentHeight = () => {
  // save current window width in the state object
  let [height, setHeight] = useState(getHeight());

// in this case useEffect will execute only once because
// it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setHeight(getHeight()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return height;
}
