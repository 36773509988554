import * as actionTypes from './action-types'


const initialState = {
  productList: {
    loading:false,
    data:null,
    lastFetch:0,
    Error:null
  },
  productArticle: {
    loading:false,
    data:null,
    lastFetch:0,
    Error:null
  }
}

const productsReducer = ( state = initialState, action ) => {
  switch(action.type){
    case actionTypes.GET_PRODUCTS_LIST_REQUEST:
      return{
        ...state,
        productList: {
          loading:true
        }
      }
      break
    case actionTypes.GET_PRODUCTS_LIST_SUCCESS:
      return{
        ...state,
        productList: {
          loading:false,
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break
    case actionTypes.GET_PRODUCTS_LIST_FAIL:
      return{
        ...state,
        productList: {
          loading:false,
          data:action.payload,
          lastFetch:0,
          Error:action.error
        }
      }
      break

    case actionTypes.GET_PRODUCTS_REQUEST:
      return{
        ...state,
        productArticle: {
          loading:true
        }
      }
      break
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return{
        ...state,
        productArticle: {
          loading:false,
          data:action.payload,
          lastFetch:Date.now()
        }
      }
      break
    case actionTypes.GET_PRODUCTS_FAIL:
      return{
        ...state,
        productArticle: {
          loading:false,
          data:action.payload,
          lastFetch:0,
          Error:action.error
        }
      }
      break

    case actionTypes.GET_PRODUCTS_RESET:
      return{
        ...initialState
      }
      break
}
  return state
}

export default productsReducer
