import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink:true,
        linkDetails:{
            label:metadata.home.linkName,
            url:metadata.home.route,
        }
    },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.services.linkName,
    //         url:metadata.services.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:'Menu',
            url:metadata.products.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:'Function Centre',
            url:metadata.packages.route,
        }
    },

    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.tools.linkName,
    //         url:metadata.tools.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.forms.linkName,
    //         url:metadata.forms.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.specialOffers.linkName,
    //         url:metadata.specialOffers.route,
    //     }
    // },


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.testimonials.linkName,
    //         url:metadata.testimonials.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.faqs.linkName,
    //         url:metadata.faqs.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.techDocs.linkName,
    //         url:metadata.techDocs.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.blogs.linkName,
    //         url:metadata.blogs.route,
    //     }
    // },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.contact.linkName,
            url:metadata.contact.route,
        }
    },
    {
        internalLink:true,
        linkDetails:{
            label:metadata.about.linkName,
            url:metadata.about.route,
        }
    },


    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.glossary.linkName,
    //         url:metadata.glossary.route,
    //     }
    // },
    // {
    //     internalLink:true,
    //     linkDetails:{
    //         label:metadata.portfolio.linkName,
    //         url:metadata.portfolio.route,
    //     }
    // }
]




const renderInternalNavLinks = () =>{

    //render each internal link that is in the headerLinks array
    let footerNavLinks =[]
    for (const navLink of headerLinks) {
        if(navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type:'link'})
    }
    // console.log(footerNavLinks)
    return footerNavLinks
}


export const footerContent = {
    columns:[
        {
            content:[
                {
                    type:'title',
                    value:APP_CONFIG.WEBSITE_NAME
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Popular Choices'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Mixed Plate',
                            url:`${metadata.products.route}/mixed-plate`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Seafood Mixed Plate',
                            url:`${metadata.products.route}/seafood-mixed-plate`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Crispy Chicken',
                            url:`${metadata.products.route}/crispy-chicken`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Vegie Mixed Plate',
                            url:`${metadata.products.route}/vegie-mixed-plate`
                        }
                    }
                }
            ]
        },
        {
            content:[
                {
                    type:'title',
                    value:'Vegetarian Options'
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Vegie Mixed Plate',
                            url:`${metadata.products.route}/vegie-mixed-plate`
                        }
                    }
                },
                {
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Chickpea',
                            url:`${metadata.products.route}/chickpea`
                        }
                    }
                }
                ,{
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Tabouli',
                            url:`${metadata.products.route}/tabouli`
                        }
                    }
                },{
                    type:'link',
                    value: {
                        internalLink:true,
                        linkDetails:{
                            label:'Vine Leaves',
                            url:`${metadata.products.route}/vine-leaves`
                        }
                    }
                }
            ]
        },
        {
            content:[
                // {
                //     type:'title',
                //     value:'Links'
                // },

                {
                    type:'img',
                    specialClass:'footerLogo',
                    value:{
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]
        }
    ]
}
