import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-2'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-1',  fill: '#FFFFFF' }, 0)
        .add({ targets: '.large-select-0',  fill: '#d5d7d9' }, 0)

    },[])



    //render() {
        return (
          <div className="loaderContainer" >
            <div className="small-loading-icon-container" style={{ width: '200px', height: '50px', top: '20px' }}>

              <svg  x="0px" y="0px" width="162.2px"
                   height="38.8px" viewBox="0 0 162.2 38.8" >

                <defs>
</defs>
                <path className="large-select-0" d="M70.8,0.8c0.1,0,0.2,0,0.3,0c2.5,0,5,0,7.5,0c0.2,0,0.3,0.1,0.3,0.2c1.7,4.1,3.4,8.2,5,12.4
	c0.5,1.2,1,2.4,1.5,3.6c0,0.1,0.1,0.1,0.1,0.2c0.2-0.4,0.3-0.8,0.5-1.1c2.1-5,4.2-10,6.3-15.1c0.1-0.1,0.1-0.2,0.3-0.2
	c2.4,0,4.8,0,7.2,0c0.1,0,0.1,0,0.2,0c0,0.2,0,0.4,0,0.6c0,0-0.1,0.1-0.2,0.1c-0.5,0.1-1,0.1-1.5,0.2c-0.7,0.1-1,0.5-1.1,1.1
	c0,0.3-0.1,0.6-0.1,0.9c0,5.7,0,11.5,0,17.2c0,0.4,0.1,0.7,0.1,1.1c0.1,0.5,0.4,0.9,1,1c0.5,0.1,1,0.1,1.6,0.2
	c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0,0.3,0,0.4c-3.5,0-6.9,0-10.4,0c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5-0.1,1-0.1,1.5-0.2
	c0.8-0.1,1.1-0.5,1.1-1.3c0-0.3,0.1-0.6,0.1-0.9c0-5.6,0-11.3,0-16.9c0-0.1,0-0.2,0-0.3l0,0c0,0.1-0.1,0.1-0.1,0.2
	C89.8,10.4,87,17,84.3,23.6c0,0.1-0.1,0.3-0.2,0.3c-0.2,0-0.4,0-0.6,0c0,0-0.1-0.1-0.1-0.2c-0.9-2.2-1.8-4.4-2.7-6.6
	c-1.8-4.2-3.5-8.5-5.3-12.7c0,0,0-0.1-0.1-0.1c0,0.1,0,0.1,0,0.2c0,4.8,0,9.6,0,14.4c0,0.8,0.1,1.5,0.3,2.2c0.3,1.2,1,1.8,2.5,2
	c0.2,0,0.4,0,0.6,0.1c0,0.2,0,0.5,0,0.7c-2.6,0-5.1,0-7.7,0c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.1,0.2-0.2c0.4-0.1,0.8-0.2,1.1-0.3
	c1-0.4,1.4-1.2,1.6-2.2c0.1-0.7,0.2-1.4,0.2-2c0-4.8,0-9.7,0-14.5c0-0.3,0-0.7-0.1-1c-0.1-0.9-0.5-1.3-1.4-1.4
	c-0.5-0.1-1-0.1-1.4-0.1C71,1.6,71,1.5,71,1.4C70.8,1.1,70.8,0.9,70.8,0.8z"/>
                <path className="large-select-0" d="M116,23.8c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.1,0.2-0.2c0.4-0.1,0.8-0.2,1.1-0.3c0.9-0.4,1.4-1.2,1.6-2.1
	c0.1-0.6,0.2-1.3,0.2-2c0-4.9,0-9.8,0-14.7c0-0.5-0.1-1-0.2-1.4s-0.3-0.7-0.7-0.7c-0.4-0.1-0.8-0.1-1.2-0.2c-0.3,0-0.6,0-1,0
	c0-0.3,0-0.5,0-0.8c0.1,0,0.2,0,0.2,0c2.8,0,5.5,0,8.3,0c0.2,0,0.3,0.1,0.4,0.2c3.5,3.9,7,7.8,10.5,11.8c1.3,1.4,2.5,2.8,3.8,4.2
	c0.1,0.1,0.1,0.1,0.2,0.3c0-0.1,0-0.2,0-0.3c0-3.6,0-7.1,0-10.7c0-0.8,0-1.7-0.2-2.5c-0.3-1.3-1.1-2-2.6-2.2c-0.2,0-0.3,0-0.5-0.1
	c0-0.1,0-0.2,0-0.4c0-0.1,0-0.2,0-0.3c2.6,0,5.1,0,7.7,0c0,0.2,0,0.4,0,0.6c0,0.1-0.1,0.1-0.2,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
	c-1,0.3-1.6,1-1.8,2c-0.1,0.6-0.2,1.2-0.2,1.8c0,6.1,0,12.2,0,18.3c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.2-0.2,0.2l0,0
	c-0.4,0-0.8,0.1-1.1,0s-0.5-0.5-0.7-0.8c-3.3-3.6-6.5-7.3-9.8-10.9c-2.8-3.1-5.5-6.2-8.3-9.3c-0.1-0.1-0.1-0.1-0.2-0.2
	c0,0,0,0-0.1,0c0,0.1,0,0.2,0,0.2c0,5.1,0,10.1,0,15.2c0,0.8,0,1.6,0.3,2.4c0.3,1.2,1.1,1.9,2.5,2.1c0.2,0,0.4,0,0.6,0.1
	c0,0.2,0,0.5,0,0.7C121.2,23.8,118.6,23.8,116,23.8z"/>
                <path className="large-select-0" d="M51.2,16C51.3,16,51.3,16,51.2,16c0.6,0,0.6,0,0.8,0.4c0.6,1.5,1.3,3,2.3,4.3s2.3,2.3,4,2.6
	c1.4,0.3,2.8,0.3,4.1-0.4c1.6-0.8,2.2-2.3,2-3.9c-0.1-1.3-0.8-2.2-1.9-2.9c-1.1-0.7-2.3-1.2-3.5-1.7c-1.5-0.6-3-1.1-4.4-1.9
	c-0.7-0.4-1.4-0.8-1.9-1.4c-1-1-1.6-2.3-1.7-3.7C50.9,6.2,51.1,5,51.6,4c0.8-1.6,2.2-2.6,3.9-3.1c1.3-0.4,2.7-0.4,4-0.3
	C60.8,0.7,62,1,63.3,1.5c0.5,0.2,0.9,0.1,1.3-0.1c0.3-0.2,0.6-0.3,0.8-0.6c0.2-0.2,0.5-0.2,0.8-0.2c0,2.6,0,5.2,0,7.8
	c-0.2,0-0.4,0-0.5,0s-0.1-0.1-0.2-0.2c-0.3-0.9-0.8-1.9-1.3-2.7c-0.8-1.3-1.7-2.4-3-3.2c-1.5-0.9-3.2-1.2-4.9-0.6
	c-1.2,0.4-2.1,1.3-2.2,2.7c-0.1,1.3,0.5,2.4,1.6,3.1c1.1,0.7,2.3,1.2,3.5,1.7c1.4,0.6,2.8,1.1,4.1,1.7c1.6,0.7,2.9,1.9,3.8,3.5
	c0.7,1.2,0.8,2.5,0.7,3.8c-0.1,1.3-0.5,2.5-1.4,3.5c-0.9,1.1-2.1,1.8-3.5,2.2c-2.8,0.8-5.5,0.7-8.2-0.2c-0.3-0.1-0.6-0.2-0.9-0.3
	c-0.4-0.2-0.8-0.1-1.2,0.1c-0.3,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.4,0.1-0.6,0.1l-0.1-0.1c0-0.1,0-0.1,0-0.2c0-2.6,0-5.1,0-7.7
	C51.2,16.2,51.2,16.1,51.2,16z"/>
                <path className="large-select-0" d="M30.2,23.1c0,0.3,0,0.5,0,0.7c-2.4,0-4.9,0-7.4,0c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.1,0.2-0.2
	c0.8-0.2,1.5-0.4,2.1-0.9c0.6-0.4,0.9-1,1.2-1.6c0.6-1.2,1.2-2.5,1.7-3.7c1.6-3.6,3.3-7.3,4.9-10.9c0.1-0.3,0.2-0.6,0.3-0.8
	c0.2-0.5,0-0.9-0.3-1.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.5c0.8-0.7,1.6-1.5,2.3-2.4c0.2-0.3,0.7-0.2,0.8,0.1
	c2.8,5.9,5.6,11.7,8.3,17.6c0.5,1.1,1,2.1,1.7,3.1c0.5,0.6,1,1.2,1.7,1.5c0.3,0.1,0.7,0.2,1.1,0.2c0.2,0,0.3,0.1,0.3,0.3
	c0,0.1,0,0.3,0,0.4c-3.7,0-7.4,0-11.1,0c0,0,0,0,0-0.1c0-0.6,0-0.6,0.6-0.7c0.5-0.1,1-0.2,1.5-0.3c0.6-0.2,0.8-0.5,0.6-1.1
	s-0.4-1.2-0.7-1.8c-0.4-0.9-0.8-1.7-1.2-2.6c0.3-0.3,0.2-0.3,0.1-0.3c-3.3,0-6.5,0-9.8,0c-0.1,0-0.2,0.1-0.3,0.1
	c-0.4,1-0.9,1.9-1.3,2.9c-0.2,0.4-0.2,0.9-0.3,1.4c-0.1,0.5,0.2,0.9,0.6,1.2c0.5,0.3,1.1,0.4,1.7,0.4C30,23.1,30.1,23.1,30.2,23.1z
	 M34.3,5.9c-1.5,3.4-3,6.7-4.5,10c3.1,0,6.1,0,9.1,0C37.4,12.6,35.8,9.3,34.3,5.9z"/>
                <path className="large-select-0" d="M7.8,0.8c5.6,0,11.1,0,16.6,0c0,0.3,0,0.6,0,1c0,0.1-0.2,0.2-0.3,0.2c-0.5,0.1-1.1,0.1-1.6,0.2
	c-1.6,0.2-2.2,0.9-2.4,2.5c0,0.5-0.1,0.9-0.1,1.4c0,6,0,12.1,0,18.1c0,2.6-0.3,5.2-1.3,7.6c-1.5,4.1-4.5,6.4-8.9,6.9
	c-1.9,0.2-3.9,0.2-5.8-0.4c-1.5-0.5-2.7-1.3-3.4-2.7c-0.6-1.3-0.8-2.6-0.3-4c0.4-1.3,1.6-2.1,3-2.2c2.7-0.2,3.8,1.7,4.1,3.7
	c0.1,0.9,0.3,1.8,0.5,2.7c0.3,1,1,1.5,1.8,1.5c1.2,0,2-0.6,2.5-1.7c0.4-1,0.5-2.1,0.5-3.2c0-0.5,0-0.9,0-1.4c0-8.4,0-16.8,0-25.1
	c0-0.6-0.1-1.3-0.2-1.9c-0.1-0.9-0.7-1.6-1.7-1.7C9.9,2.2,9,2.1,8.1,2C7.7,2,7.6,1.9,7.6,1.5C7.8,1.2,7.8,1,7.8,0.8z"/>
                <path className="large-select-0" d="M113.7,23.1c0,0.3,0,0.5,0,0.7c-3.7,0-7.4,0-11.1,0c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1,0,1.5-0.1
	c1-0.1,1.4-0.4,1.5-1.7c0-0.2,0-0.4,0-0.5c0-5.7,0-11.3,0-17c0-0.3,0-0.6-0.1-0.8c-0.1-0.9-0.5-1.2-1.3-1.3c-0.5-0.1-1-0.1-1.5-0.1
	c0-0.2,0-0.4,0-0.7c3.6,0,7.1,0,10.7,0c0,0.2,0,0.5,0,0.7c-0.4,0-0.9,0-1.3,0.1c-0.2,0-0.5,0-0.7,0.1c-0.8,0.2-1.1,0.6-1.2,1.3
	c0,0.3-0.1,0.7-0.1,1c0,5.6,0,11.2,0,16.8c0,0.5,0.1,0.9,0.2,1.4c0.1,0.4,0.4,0.7,0.8,0.8c0.5,0.1,1.1,0.2,1.6,0.2
	C113.3,23.1,113.5,23.1,113.7,23.1z"/>
                <path className="large-select-0" d="M161,24.4c-4.1,0-8.1,0-12.1,0c0-0.3,0-0.5,0-0.8c0-0.1,0.1-0.1,0.2-0.1c0.6-0.1,1.3-0.1,1.9-0.3
	c1.1-0.2,1.5-0.7,1.6-1.8c0-0.3,0.1-0.6,0.1-0.9c0-4.9,0-9.8,0-14.8c0-0.5,0-0.9-0.1-1.4c0-0.5-0.2-0.7-0.7-0.8
	c-0.7-0.1-1.4-0.1-2-0.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.8-0.1-0.6,0.7-0.7c1.4-0.3,2.8-0.7,4.1-1.3c0.6-0.3,1.2-0.6,1.8-1
	c0.4,0.1,0.6,0.1,0.8,0.1l0.1,0.1c0,0.1,0,0.2,0,0.2c0,6.7,0,13.3,0,20c0,0.5,0.1,1.1,0.2,1.6c0.1,0.5,0.5,0.9,1,1.1
	c0.8,0.3,1.6,0.3,2.3,0.4c0.2,0,0.3,0.1,0.3,0.3C161,24,161,24.2,161,24.4z"/>
                <g>
	<g>
		<path className="large-select-0" d="M129.5,30.4C129.5,30.4,129.5,30.3,129.5,30.4c0-0.2,0-0.2,0.1-0.2c1,0,2,0,3,0c0.6,0,1.2,0.1,1.8,0.3
			c0.4,0.2,0.8,0.4,1.1,0.8c0.5,0.8,0.2,1.8-0.7,2.2c-0.2,0.1-0.5,0.1-0.7,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.5,0.6,0.7,1
			c0.3,0.4,0.5,0.9,0.8,1.3c0.1,0.1,0.2,0.2,0.3,0.3c0.2,0.2,0.5,0.4,0.9,0.4c0,0.1,0,0.1,0,0.2H137c-0.7,0-1.4,0-2,0
			c-0.1,0-0.1,0-0.2-0.1c-0.4-0.7-0.7-1.5-1.1-2.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.5-0.4-0.9-0.5c-0.2,0-0.3,0-0.5,0v0.1
			c0,0.8,0,1.6,0,2.4c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.3,0,0.5,0c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.3,0
			c0-0.1,0-0.1,0-0.2c0.2,0,0.3,0,0.5,0c0.3,0,0.4-0.1,0.5-0.5c0-0.1,0-0.2,0-0.2c0-1.7,0-3.4,0-5.1c0-0.1,0-0.2,0-0.3
			c0-0.3-0.1-0.4-0.4-0.4C129.8,30.4,129.7,30.4,129.5,30.4z M131.8,33.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.1-0.4,1.2-1.1
			c0-0.2,0-0.4,0-0.5c0-0.4-0.2-0.8-0.5-1c-0.5-0.4-1.1-0.4-1.7-0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.1,0,0.2,0,0.4
			C131.8,31.9,131.8,32.7,131.8,33.5C131.8,33.4,131.8,33.4,131.8,33.5z"/>
    <path className="large-select-0" d="M84.5,30.4c0-0.1,0-0.1,0-0.2h0.1c1,0,2.1,0,3.1,0c0.6,0,1.2,0.1,1.7,0.3c0.4,0.2,0.8,0.4,1,0.7
			c0.6,0.8,0.3,1.9-0.7,2.3c-0.2,0.1-0.5,0.1-0.7,0.2l0,0c0.4,0.3,0.7,0.7,0.9,1.1c0.3,0.5,0.5,0.9,0.8,1.4c0.1,0.2,0.3,0.5,0.6,0.6
			c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.2h-0.1c-0.7,0-1.4,0-2,0c-0.1,0-0.1,0-0.2-0.1
			c-0.4-0.8-0.8-1.6-1.2-2.4c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-0.3-0.5-0.5-0.9-0.5c-0.2,0-0.3,0-0.5,0v0.1c0,0.8,0,1.6,0,2.4
			c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.4,0.5,0.5c0.2,0,0.3,0,0.5,0c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.3,0c0-0.1,0-0.1,0-0.2
			c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.2,0-0.3c0-1.7,0-3.3,0-5c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.4-0.4-0.5
			C84.8,30.4,84.7,30.4,84.5,30.4z M86.8,33.4c0.5,0,0.9,0,1.4-0.1c0.7-0.2,1.1-0.6,1.2-1.3c0.1-0.7-0.3-1.3-1-1.5
			c-0.4-0.1-0.7-0.1-1.1-0.1c-0.3,0-0.4,0.1-0.4,0.4v0.1C86.8,31.7,86.8,32.5,86.8,33.4L86.8,33.4z"/>
    <path className="large-select-0" d="M146.1,30.4c0-0.1,0-0.1,0-0.2h0.1c0.8,0,1.6,0,2.4,0c0.1,0,0.2,0,0.2,0.1c1.4,1.6,2.9,3.2,4.3,4.8
			c0,0,0,0,0.1,0.1c0-0.1,0-0.1,0-0.1c0-1.1,0-2.2,0-3.3c0-0.2,0-0.4-0.1-0.7c-0.1-0.4-0.3-0.6-0.7-0.6h-0.1c-0.1,0-0.1,0-0.1-0.1
			s0-0.1,0.1-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.1,0,0.1,0.1s0,0.2-0.1,0.2s-0.2,0-0.3,0.1c-0.4,0.1-0.5,0.4-0.5,0.8c0,0.2,0,0.4,0,0.6
			c0,1.8,0,3.5,0,5.3c0,0,0,0.1,0,0.2c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1-0.1c-0.8-0.8-1.5-1.7-2.3-2.5c-1.1-1.2-2.2-2.5-3.3-3.7
			c0,0,0-0.1-0.1-0.1l0,0v0.1c0,1.5,0,3.1,0,4.6c0,0.2,0,0.5,0.1,0.7c0.1,0.4,0.3,0.5,0.7,0.6c0.1,0,0.2,0,0.2,0c0,0.1,0,0.1,0,0.2
			c-0.8,0-1.6,0-2.3,0c0-0.1,0-0.1,0-0.2c0.1,0,0.2,0,0.2-0.1c0.4-0.1,0.6-0.4,0.7-0.7c0-0.2,0.1-0.4,0.1-0.6c0-1.4,0-2.9,0-4.3
			c0-0.2,0-0.3,0-0.5s-0.1-0.3-0.3-0.3s-0.3,0-0.5,0C146.2,30.4,146.1,30.4,146.1,30.4z"/>
    <path className="large-select-0" d="M98.5,37.2c-2,0-4.1,0-6.1,0c0-0.1,0-0.1,0-0.2c0.1,0,0.3,0,0.4,0c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.1,0-0.2
			c0-1.7,0-3.4,0-5.1c0-0.1,0-0.2,0-0.3c0-0.3-0.1-0.4-0.4-0.5c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.1,0-0.2h0.1c1.8,0,3.7,0,5.5,0
			c0.1,0,0.1,0,0.2,0.1c0.2,0.5,0.4,1.1,0.7,1.6V32c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.7-0.8-1.1-1.1s-0.8-0.4-1.3-0.4
			c-0.4,0-0.8,0-1.2,0c-0.1,0-0.1,0-0.2,0s-0.1,0.1-0.1,0.2v0.1c0,0.9,0,1.8,0,2.7v0.1l0,0c0.5,0,0.9,0,1.4-0.1
			c0.6-0.1,1-0.5,1.2-1.2c0-0.2,0-0.2,0.2-0.1c0,1.1,0,2.1,0,3.2c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.3-0.1-0.4c-0.2-0.7-0.6-1-1.3-1.1
			c-0.4-0.1-0.8,0-1.3-0.1l0,0v0.1c0,0.8,0,1.7,0,2.5c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2H95c0.6,0,1.3,0.1,1.9-0.1
			s1.1-0.5,1.4-1c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.1-0.1,0.3-0.1C98.9,35.8,98.7,36.5,98.5,37.2z"/>
    <path className="large-select-0" d="M100.2,37.3L100.2,37.3c0-0.9,0-1.6,0-2.4c0,0,0-0.1,0.1-0.1s0.1,0,0.2,0.1c0.2,0.5,0.4,1,0.8,1.4
			c0.6,0.8,1.5,0.9,2.2,0.7c0.4-0.1,0.7-0.4,0.8-0.9c0.1-0.5-0.1-0.9-0.5-1.2c-0.3-0.3-0.7-0.4-1.1-0.6c-0.5-0.2-1-0.4-1.4-0.6
			c-0.3-0.2-0.7-0.5-0.8-0.8c-0.5-1.1,0-2.4,1.2-2.7c0.6-0.1,1.2-0.1,1.8,0c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.3,0,0.4,0
			s0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.1v0.1c0,0.7,0,1.4,0,2.1c0,0,0,0.1-0.1,0.1s-0.1,0-0.2-0.1c-0.2-0.5-0.4-1-0.8-1.3
			c-0.4-0.5-0.9-0.7-1.6-0.7c-0.3,0-0.6,0.1-0.8,0.4c-0.4,0.4-0.3,1,0.1,1.4c0.3,0.2,0.7,0.4,1,0.5c0.5,0.2,0.9,0.4,1.4,0.6
			c0.5,0.2,1,0.6,1.2,1.2c0.5,1.1,0,2.3-1.1,2.7c-0.6,0.2-1.2,0.3-1.8,0.2c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.1-0.8,0.2
			C100.4,37.3,100.3,37.3,100.2,37.3z"/>
    <path className="large-select-0" d="M123.7,30.4c-0.2,0-0.3,0-0.5,0c-0.3,0-0.4,0.1-0.5,0.5c0,0.1,0,0.2,0,0.2c0,1.1,0,2.1,0,3.2
			c0,0.5,0,0.9,0.2,1.4c0.2,0.6,0.7,1,1.3,1.1c0.8,0.2,1.6,0.1,2.3-0.2c0.7-0.3,1.1-0.8,1.1-1.6c0-1,0-1.9,0-2.9c0-0.3,0-0.7,0-1
			c-0.1-0.5-0.3-0.7-0.9-0.8h-0.1c0-0.1,0-0.1,0-0.2c0.8,0,1.5,0,2.3,0v0.1c0,0.1,0,0.1-0.1,0.1s-0.2,0-0.3,0.1
			c-0.4,0.1-0.5,0.5-0.6,0.8c0,0.2,0,0.4,0,0.5c0,0.9,0,1.9,0,2.8c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.7-0.7,1.1-1.4,1.3
			c-0.7,0.2-1.5,0.3-2.3,0.2c-0.5,0-1-0.1-1.4-0.3c-0.7-0.4-1.2-0.9-1.3-1.8c0-0.3-0.1-0.7-0.1-1c0-1,0-2,0-3c0-0.1,0-0.3,0-0.4
			c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.1-0.1c1,0,2,0,3.1,0C123.7,30.2,123.7,30.2,123.7,30.4
			z"/>
    <path className="large-select-0" d="M115.5,31c0.2-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3,0c0.9,1.9,1.8,3.7,2.7,5.6
			c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.3,0.5,0.5,0.9,0.5c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.4,0c0-0.1,0-0.1,0-0.2c0.2,0,0.3-0.1,0.5-0.1
			c0.1,0,0.1,0,0.2-0.1s0.2-0.1,0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.1-0.1-0.1-0.1-0.1c-1,0-1.9,0-2.9,0
			c0,0-0.1,0-0.1,0.1c-0.2,0.4-0.3,0.7-0.5,1.1c-0.1,0.4,0.1,0.6,0.5,0.7c0.1,0,0.2,0,0.3,0c0,0.1,0,0.1,0,0.2c-0.7,0-1.5,0-2.2,0
			c-0.1-0.2,0-0.2,0.1-0.2c0.5-0.1,0.8-0.4,1-0.8c0.2-0.5,0.4-0.9,0.7-1.4c0.5-1,0.9-2.1,1.4-3.1l0,0
			C115.7,31.4,115.8,31.2,115.5,31z M117.3,34.8c-0.5-1-0.9-2-1.4-3c-0.5,1-0.9,2-1.4,3C115.5,34.8,116.4,34.8,117.3,34.8z"/>
    <path className="large-select-0" d="M140.6,31c0.2-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3,0c0.9,1.9,1.8,3.7,2.7,5.6
			c0.1,0.2,0.2,0.5,0.4,0.7c0.2,0.3,0.5,0.5,0.9,0.5c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.4,0c0-0.1,0-0.1,0-0.2c0.2,0,0.3-0.1,0.5-0.1
			c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.3-0.6-0.4-0.9c0-0.1-0.1-0.1-0.1-0.1
			c-1,0-1.9,0-2.9,0c0,0-0.1,0-0.1,0.1c-0.2,0.4-0.3,0.7-0.5,1.1c-0.1,0.4,0.1,0.6,0.5,0.7c0.1,0,0.2,0,0.3,0c0,0.1,0,0.1,0,0.2
			c-0.7,0-1.5,0-2.2,0c-0.1-0.2,0-0.2,0.1-0.2c0.5-0.1,0.8-0.4,1-0.8c0.2-0.5,0.4-0.9,0.7-1.4c0.5-1,0.9-2.1,1.4-3.1l0,0
			C140.8,31.4,140.8,31.2,140.6,31z M142.4,34.8c-0.5-1-0.9-2-1.4-3c-0.5,1-0.9,2-1.4,3C140.6,34.8,141.5,34.8,142.4,34.8z"/>
    <path className="large-select-0" d="M111.4,37c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.3,0c0-0.1,0-0.1,0-0.2c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5
			c0-0.1,0-0.1,0-0.2c0-1.7,0-3.5,0-5.2c0-0.1,0-0.2,0-0.4s-0.1-0.2-0.3-0.3c-0.5-0.1-0.9,0.1-1.3,0.3c-0.5,0.3-0.8,0.8-1.1,1.3
			c0,0,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0.1-0.6,0.2-1.2,0.4-1.9c0-0.1,0-0.1,0.1-0.1s0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1
			c1.7,0,3.4,0,5.1,0c0.1,0,0.2,0,0.4-0.1c0.1,0,0.2,0,0.4,0v0.1c0.1,0.5,0.2,1,0.3,1.4c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.1-0.1,0.1
			s-0.1,0-0.1-0.1c-0.3-0.4-0.6-0.9-1-1.2s-0.8-0.5-1.3-0.5c-0.4,0-0.5,0.1-0.5,0.5c0,0.1,0,0.1,0,0.2c0,1.7,0,3.4,0,5.1
			c0,0.1,0,0.2,0,0.3c0,0.3,0.2,0.4,0.5,0.5C111,36.9,111.2,36.9,111.4,37z"/>
    <path className="large-select-0" d="M160.2,37c0,0.1,0,0.1,0,0.2c-1.1,0-2.2,0-3.3,0c0-0.1,0-0.1,0-0.2c0.2,0,0.3,0,0.5,0c0.3,0,0.5-0.2,0.5-0.5
			l0,0c0-1.9,0-3.7,0-5.6c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.4-0.1-0.8,0-1.1,0.2c-0.4,0.2-0.8,0.6-1.1,1
			c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0.1-0.6,0.2-1.2,0.4-1.9c0-0.1,0-0.1,0.1-0.1
			s0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c1.7,0,3.4,0,5.1,0c0.1,0,0.2,0,0.3-0.1h0.1c0.1,0,0.2,0,0.3,0c0,0.2,0.1,0.4,0.1,0.5
			c0.1,0.3,0.1,0.5,0.1,0.8c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1c0,0,0,0-0.1,0c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3-0.4-0.6-0.8-1.1-1
			c-0.3-0.2-0.7-0.2-1.1-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0,0.3,0,0.4c0,1.7,0,3.5,0,5.2c0,0.1,0,0.1,0,0.2c0,0.3,0.2,0.5,0.5,0.5
			C159.8,36.9,160,36.9,160.2,37z"/>
	</g>
                  <g>
		<path className="large-select-0" d="M46.2,30.9c0.2-0.2,0.5-0.5,0.7-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3,0c0.9,1.9,1.8,3.8,2.7,5.7
			c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.3,0.5,0.6,0.9,0.5c0,0.1,0,0.1,0,0.2c-1.1,0-2.3,0-3.4,0c0-0.1,0-0.1,0-0.2c0.2,0,0.4-0.1,0.5-0.1
			s0.1,0,0.2-0.1c0.1-0.1,0.2-0.1,0.1-0.3c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.3-0.6-0.4-1c0-0.1-0.1-0.1-0.1-0.1c-1,0-2,0-3,0
			c0,0-0.1,0-0.1,0.1c-0.2,0.4-0.3,0.7-0.5,1.1c-0.1,0.4,0.1,0.6,0.5,0.7c0.1,0,0.2,0,0.3,0c0,0.1,0,0.1,0,0.2c-0.8,0-1.5,0-2.3,0
			c-0.1-0.2,0-0.2,0.1-0.3c0.5-0.1,0.8-0.4,1-0.8c0.2-0.5,0.4-1,0.7-1.4c0.5-1,0.9-2.1,1.4-3.1l0,0C46.4,31.3,46.5,31,46.2,30.9z
			 M48.1,34.7c-0.5-1-1-2.1-1.5-3.1c-0.5,1.1-0.9,2.1-1.4,3.1C46.2,34.7,47.1,34.7,48.1,34.7z"/>
                    <path className="large-select-0" d="M37.4,37.1C37.4,37.1,37.3,37.1,37.4,37.1c0-0.2,0-0.2,0.2-0.2c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2
			s0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-1.6,0-3.3,0-4.9c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.3-0.3c-0.2,0-0.3,0-0.5-0.1
			c-0.1,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1l0,0c1.1,0,2.2,0,3.2,0c0.5,0,1,0.1,1.4,0.2c0.3,0.1,0.6,0.3,0.9,0.5
			c0.5,0.6,0.4,1.5-0.3,2c-0.3,0.2-0.6,0.3-1,0.4c-0.1,0-0.1,0-0.1,0.1s0,0.1,0.1,0.1c0.4,0.1,0.9,0.2,1.2,0.5
			c0.5,0.4,0.7,0.9,0.7,1.5c-0.1,0.6-0.4,1-1,1.3C42,36.9,41.5,37,41,37C39.9,37.1,38.7,37.1,37.4,37.1L37.4,37.1z M39.6,34.8
			L39.6,34.8c0,0.3,0,0.6,0,0.9c0,0.2,0,0.4,0,0.6c0,0.3,0.2,0.4,0.5,0.5h0.1c0.4,0,0.7,0,1.1-0.1c0.4-0.1,0.7-0.4,0.8-0.8
			c0.1-0.4,0.1-0.9,0-1.3s-0.3-0.7-0.7-0.9c-0.2-0.1-0.5-0.2-0.7-0.2c-0.4,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.1,0.1
			C39.6,34,39.6,34.4,39.6,34.8z M39.6,33.3c0.3,0,0.5,0,0.7,0s0.4,0,0.6-0.1c0.5-0.1,0.8-0.4,0.9-0.8c0.1-0.4,0.1-0.7,0-1.1
			c-0.1-0.4-0.3-0.7-0.7-0.8c-0.3-0.1-0.6-0.1-0.9-0.1c-0.1,0-0.2,0-0.3,0s-0.2,0.1-0.2,0.2c0,0.1,0,0.2,0,0.3
			C39.6,31.7,39.6,32.4,39.6,33.3C39.6,33.2,39.6,33.2,39.6,33.3z"/>
                    <path className="large-select-0" d="M58.8,35.1C58.8,35,58.8,35,58.8,35.1c0-1.1,0-2.2,0-3.2c0-0.2,0-0.5-0.1-0.7c-0.1-0.4-0.4-0.6-0.8-0.7l0,0
			c-0.1,0-0.2-0.1-0.1-0.2l0,0c0,0,0,0,0.1,0c0.7,0,1.4,0,2.1,0c0.1,0,0.1,0,0.1,0.1s0,0.1-0.1,0.1c-0.2,0-0.5,0.1-0.6,0.4
			c-0.1,0.2-0.2,0.4-0.2,0.5c0,0.2,0,0.3,0,0.5c0,1.8,0,3.5,0,5.3c0,0.1,0,0.1-0.1,0.1c-0.2,0-0.3-0.1-0.5-0.2
			c-1.2-1.4-2.5-2.8-3.7-4.2c-0.6-0.6-1.1-1.3-1.7-1.9c0,0,0,0-0.1-0.1V31c0,1.5,0,3,0,4.5c0,0.2,0,0.5,0.1,0.7
			c0.1,0.4,0.3,0.6,0.7,0.6c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0.1s0,0.1,0,0.2c-0.8,0-1.5,0-2.3,0c0-0.1,0-0.1,0-0.2
			c0.1,0,0.2,0,0.2-0.1c0.4-0.1,0.6-0.4,0.6-0.8c0-0.2,0-0.4,0-0.6c0-1.4,0-2.9,0-4.3c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.3-0.3
			s-0.3,0-0.5,0h-0.1c0-0.1,0-0.1,0-0.2h0.1c0.8,0,1.6,0,2.4,0c0.1,0,0.1,0,0.2,0.1C55.8,31.8,57.3,33.4,58.8,35.1
			C58.7,35,58.7,35.1,58.8,35.1C58.7,35.1,58.8,35.1,58.8,35.1z"/>
                    <path className="large-select-0" d="M34.8,35.2c0-0.3-0.1-0.5-0.2-0.8c-0.2-0.4-0.5-0.6-0.9-0.7c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.4,0-0.5,0
			s-0.1,0-0.1,0.1c0,0.9,0,1.8,0,2.6c0,0.1,0,0.2,0,0.3s0.1,0.1,0.2,0.2c0.1,0,0.1,0,0.2,0c0.4,0,0.8,0,1.2,0c0.8,0,1.4-0.4,1.9-1
			c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.1,0.1-0.1,0.3-0.1v0.1c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.1,0,0.1-0.1,0.1c-1.1,0-2.1,0-3.2,0
			c-0.9,0-1.7,0-2.6,0h-0.1l0,0c0-0.2,0-0.2,0.2-0.2c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.2,0.4-0.5c0-0.1,0-0.2,0-0.2c0-1.7,0-3.3,0-5
			c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.3-0.3c-0.2,0-0.3,0-0.5-0.1h-0.1c0-0.1,0-0.1,0-0.2l0,0c0,0,0,0,0.1,0c1.8,0,3.6,0,5.4,0
			c0.1,0,0.1,0,0.1,0.1c0.2,0.6,0.5,1.1,0.7,1.7V32c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.7-1-1c-0.3-0.2-0.5-0.4-0.9-0.5
			c-0.2,0-0.5-0.1-0.7-0.1c-0.4,0-0.7,0-1.1,0c-0.2,0-0.2,0.1-0.2,0.2c0,0.5,0,1,0,1.5c0,0.4,0,0.8,0,1.2c0,0.1,0,0.1,0.1,0.1
			c0.3,0,0.7,0,1,0c0.4,0,0.7-0.1,1-0.4c0.2-0.2,0.4-0.5,0.4-0.8c0,0,0,0,0-0.1l0,0c0.1,0,0.1,0,0.2,0v0.1c0,1,0,1.9,0,2.9
			C35,35.2,35,35.2,34.8,35.2L34.8,35.2z"/>
                    <path className="large-select-0" d="M79.1,32c0.1,0,0.1,0,0.2,0v0.1c0,1,0,2,0,2.9c0,0.1,0,0.1-0.1,0.1c0,0,0,0-0.1,0c0-0.1,0-0.3-0.1-0.4
			c-0.1-0.6-0.5-0.9-1.1-1c-0.3-0.1-0.5-0.1-0.8-0.1c-0.2,0-0.4,0-0.6,0c0,0,0,0-0.1,0v0.1c0,0.8,0,1.6,0,2.4c0,0.2,0,0.3,0,0.5
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.1,0c0.8,0,1.5-0.4,2-1c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.1,0.1-0.1,0.3-0.1
			c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5-0.3,1.1-0.4,1.6c0,0.1,0,0.1-0.1,0.1c-2,0-3.9,0-5.9,0c-0.1,0-0.1,0-0.1-0.1l0,0
			c0-0.1,0-0.1,0.1-0.1s0.3,0,0.4,0c0.3,0,0.4-0.2,0.4-0.5c0-0.1,0-0.2,0-0.2c0-1.7,0-3.3,0-5c0-0.1,0-0.3,0-0.4
			c0-0.1-0.1-0.2-0.3-0.2c-0.2,0-0.3,0-0.5-0.1c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.1-0.1c1.8,0,3.6,0,5.4,0c0.1,0,0.1,0,0.1,0.1
			c0.2,0.6,0.5,1.1,0.7,1.7v0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.4-0.6-0.7-1-1c-0.3-0.2-0.5-0.4-0.9-0.5c-0.2,0-0.4-0.1-0.6-0.1
			c-0.4,0-0.8,0-1.2,0c-0.2,0-0.2,0.1-0.2,0.2c0,0.2,0,0.4,0,0.6c0,0.7,0,1.4,0,2.1v0.1l0,0c0.4,0,0.9,0,1.3-0.1
			c0.7-0.1,1.1-0.5,1.2-1.2C79.1,32.1,79.1,32.1,79.1,32z"/>
                    <path className="large-select-0" d="M62.9,33.6C62.9,33.7,62.9,33.7,62.9,33.6c0,0.9,0,1.8,0,2.6c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.2,0.2,0.2
			s0.1,0,0.2,0c0.4,0,0.9,0,1.3,0c0.8,0,1.5-0.4,1.9-1c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.1-0.2,0.3-0.1c0,0.1,0,0.2-0.1,0.3
			c-0.2,0.6-0.3,1.1-0.5,1.7c0,0.1,0,0.1-0.1,0.1c-0.4,0-0.7,0-1.1,0c-1.6,0-3.1,0-4.7,0h-0.1l0,0c0-0.2,0-0.2,0.2-0.2
			c0.1,0,0.2,0,0.3,0c0.3-0.1,0.4-0.2,0.4-0.4c0-0.1,0-0.2,0-0.3c0-1.7,0-3.3,0-5c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.3-0.3
			s-0.3,0-0.5-0.1c-0.1,0-0.1,0-0.1-0.1v-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1,0c1.8,0,3.6,0,5.4,0c0.1,0,0.1,0,0.1,0.1
			c0.2,0.6,0.5,1.1,0.7,1.7c0,0,0,0,0,0.1c-0.1,0-0.2,0-0.3-0.1c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.6-0.4-1-0.5
			c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2,0.1-0.2,0.2C62.8,31.6,62.8,32.5,62.9,33.6L62.9,33.6L62.9,33.6
			c0.3-0.1,0.6-0.1,1-0.1s0.8-0.2,1.1-0.5c0.2-0.2,0.3-0.4,0.4-0.7c0-0.2,0-0.2,0.2-0.1v0.1c0,1,0,2,0,3c0,0,0,0.1-0.1,0.1
			s-0.1,0-0.1-0.1c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.4-0.6-0.6-1-0.7c-0.2,0-0.5-0.1-0.7-0.1C63.3,33.6,63.1,33.6,62.9,33.6z"/>
                    <path className="large-select-0" d="M68.3,34.7C68.4,34.7,68.4,34.7,68.3,34.7c0.1,0,0.2,0,0.2,0.1c0.1,0.3,0.3,0.6,0.4,0.9
			c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.8,0.3,1.3,0.3c0.3,0,0.6-0.1,0.8-0.3c0.3-0.2,0.4-0.5,0.4-0.8c0-0.5-0.2-0.8-0.6-1.1
			c-0.4-0.3-0.8-0.4-1.2-0.6c-0.5-0.2-0.9-0.4-1.4-0.6c-0.4-0.2-0.6-0.5-0.8-1c-0.2-0.6-0.1-1.2,0.3-1.7c0.3-0.4,0.7-0.6,1.2-0.7
			c0.7-0.1,1.3,0,2,0.2c0.3,0.1,0.5,0.1,0.7-0.1l0.1-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1c0,0.2,0,0.5,0,0.7c0,0.5,0,1,0,1.5v0.1
			c-0.1,0-0.2,0-0.2-0.1c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.4-0.6-0.7-1-1c-0.5-0.2-0.9-0.3-1.4-0.1c-0.8,0.3-0.7,1.2-0.2,1.6
			c0.3,0.2,0.7,0.4,1,0.5c0.5,0.2,0.9,0.4,1.4,0.6c0.5,0.2,0.8,0.6,1.1,1c0.2,0.4,0.3,0.8,0.2,1.2c0,0.4-0.2,0.8-0.5,1.2
			c-0.3,0.3-0.7,0.5-1.2,0.6c-0.8,0.2-1.6,0.1-2.4-0.2c-0.2-0.1-0.4,0-0.5,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.1
			C68.3,36.4,68.3,35.5,68.3,34.7z"/>
                    <path className="large-select-0" d="M29.4,35.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.4-0.2,0.9-0.4,1.3c0,0.1,0,0.1-0.1,0.1c-2,0-4,0-6,0
			c0,0,0,0-0.1,0c0-0.1,0-0.1,0-0.2c0,0,0,0,0.1,0s0.3,0,0.4,0c0.3,0,0.4-0.2,0.4-0.5c0-0.1,0-0.2,0-0.2c0-1.7,0-3.3,0-5
			c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2-0.3-0.3c-0.2,0-0.3,0-0.5-0.1h-0.1c0-0.1,0-0.1,0-0.2h0.1c1,0,1.9,0,2.9,0c0.1,0,0.1,0,0.1,0.1
			v0.1c-0.1,0-0.3,0-0.4,0h-0.1c-0.2,0-0.3,0.2-0.3,0.4c0,0.1,0,0.2,0,0.3c0,1.7,0,3.4,0,5.1c0,0.1,0,0.3,0,0.4
			c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.2,0c0.4,0,0.8,0,1.3,0c0.8,0,1.5-0.4,2-1c0.2-0.2,0.3-0.5,0.5-0.7
			C29.2,35,29.2,35,29.4,35.1z"/>
	</g>
</g>
</svg>

            </div>
          </div>
        )
    //}
}

export default BigLogoLoader
