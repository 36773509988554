import React, {Fragment} from 'react'
import config from '../../../../config.json'

require ('./ContactIcons.scss')

const ContactIcons = props =>{

  const {primaryMobile, email, landline} = props

  return(
    <Fragment>
      {landline && (
        <div className="contactTile">
          <div className="">
            Ph:
          </div>
          <div className="">
            <a href={`tel:${config.CONTACT_INFORMATION.PRIMARY_LANDLINE}`} title={`Email ${config.WEBSITE_NAME}`} >{config.CONTACT_INFORMATION.PRIMARY_LANDLINE}</a>
          </div>
        </div>
      )}
      {primaryMobile && (
        <div className="contactTile">
          <div className="">
            Mob:
          </div>
          <div className="">
            <a href={`tel:${config.CONTACT_INFORMATION.PRIMARY_MOBILE}`} title={`Email ${config.WEBSITE_NAME}`} >{config.CONTACT_INFORMATION.PRIMARY_MOBILE}</a>
          </div>
        </div>
      )}
      {email && (
        <div className="contactTile">
          <div className="">
            Email:
          </div>
          <div className="">
            <a href={`mailto:${config.CONTACT_INFORMATION.EMAIL}`} title={`Email ${config.WEBSITE_NAME}`} >{config.CONTACT_INFORMATION.EMAIL}</a>
          </div>
        </div>
      )}



    </Fragment>
    )
}

export default ContactIcons;
