import React from 'react'
import ContactIcons from '../contact-icons'

require ('./HeaderContactDetails.scss')

const HeaderContactDetails = props =>{

  const {primaryMobile, email, landline} = props

  //todo mahmod - change contact icons
  return(
    <div className="contactTileInHeader">
      {landline && (
        <ContactIcons landline />
      )}
      {primaryMobile && (
        <ContactIcons primaryMobile />
      )}
      {email && (
        <ContactIcons email />
      )}
    </div>
    )
}

export default HeaderContactDetails;
